.itemCard {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    padding: 16px;
    margin-bottom: 16px;
    border-color: #eaeaea;
    border-style: solid;
    border-radius: 8px;
    border-width: 1px;
    gap: 16px;
    text-decoration: none;
}
/* .itemCard:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
} */

.info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
}

.bottomInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.itemImage {
    height: 88px;
    width: 88px;
    background-color: #eee;
    border-radius: 4px;
    flex-shrink: 0;
}

.itemTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 0px;

    color: #212529;
}

.description {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    margin-top: 0px;
    margin-bottom: 0px;

    color: #6C757D;
}

.price {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    margin-top: 0px;
    margin-bottom: 0px;

    color: #28A745;
}

.people {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    margin-top: 0px;
    margin-bottom: 0px;

    color: #6C757D
}

.normalize {
    text-decoration: none;
}

.topInfo {
    margin-bottom: 16px;
}