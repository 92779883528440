.navBase {
    background-color: #fff;
    padding: 16px;
}

.optionsList {
    list-style-type: none;
    padding: 0px;
}

li{
    display: flex;
    flex: 1;
    margin-bottom: 16px;
}

.navbarOption {
    text-decoration: none;
    display: flex;
    flex: 1;
    line-height: 20px;
    font-size: 16px;
    border-radius: 4px;
    align-items: center;
    border: none;
    padding: 16px;
    background-color: transparent;
    color: #333;
    cursor: pointer;
}

.navbarOptionActive {
    text-decoration: none;
    display: flex;
    flex: 1;
    line-height: 20px;
    font-size: 16px;
    border-radius: 4px;
    color: #2c25b0;
    background-color: transparent;
    align-items: center;
    border: none;
    padding: 16px;
    cursor: pointer;
}

.action{
    background-color: transparent;
    border: transparent;
    cursor: pointer;
}

.navbarOption svg {
    margin-right: 8px;
}
.navbarOptionActive svg {
    margin-right: 8px;
}