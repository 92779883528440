.categoryItemAdmin {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    padding: 16px;
    margin-bottom: 16px;
    border-color: #eaeaea;
    border-style: solid;
    border-radius: 8px;
    border-width: 1px;
    gap: 16px;
    text-decoration: none;
}

.categoryItemAdmin:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
}

.categoryItemAdmin-details {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
}

.categoryItemAdmin-Image {
    height: 48px;
    width: 48px;
    background-color: #eee;
    border-radius: 4px;
    flex-shrink: 0;
}

.categoryItemAdmin-details-info{
    display: flex;
}


.categoryItemAdmin-details-info-title {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 0px;

    color: #212529;
}

.categoryItemAdmin-details-info-description {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    margin-top: 0px;
    margin-bottom: 0px;

    color: #6C757D;
}

.categoryItemAdmin-details-settings {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.categoryItemAdmin-details-settings *{
    margin-left: 16px 
}

.categoryItemAdmin-details-settings-price {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    margin-top: 0px;
    margin-bottom: 0px;

    color: #28A745;
}

.people {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    margin-top: 0px;
    margin-bottom: 0px;

    color: #6C757D
}

.categoryItemsList{
    border-radius: 8px;
    height: 48px;
    display: flex;
    background-color: green;
    width: 100%;
}