.categorySelected {
    padding: 8px 12px;
    background-color: #54886B;
    border-radius: 16px;
    color: #fff;
}

.categoryUnselected {
    padding: 8px 12px;
    border: solid 1px #54886B;
    border-radius: 16px;
}

.categoryName {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    white-space: nowrap;

}