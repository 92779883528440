.header {
    position: static;
    display: flex;
    height: 72px;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 16%);
    z-index: 1;
    background-color: #fefefe;
}

.headerRight {
    display: flex;
    flex-direction: row;
    align-items: center
}

.userInfo {
    height: 40px;
    margin-right: 16px;
}

.userName {
    line-height: 24px;
    margin: 0px;
    font-size: 14px;
    font-weight: bold;
}

.store {
    line-height: 16px;
    font-size: 14px;
    margin: 0px
}