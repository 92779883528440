.itemAdmin {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    padding: 16px;
    margin-bottom: 16px;
    border-color: #eaeaea;
    border-style: solid;
    border-radius: 8px;
    border-width: 1px;
    gap: 16px;
    text-decoration: none;
}
.itemAdmin:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
}

.itemAdmin-details {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
}

.itemAdmin-Image {
    height: 48px;
    width: 48px;
    background-color: #eee;
    border-radius: 4px;
    flex-shrink: 0;
}

.itemAdmin-details-info-title {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 0px;

    color: #212529;
}

.itemAdmin-details-info-category {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    margin-top: 0px;
    margin-bottom: 8px;

    color: #6C757D;
}

.itemAdmin-details-settings {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
}
.itemAdmin-details-settings *{
    margin-left: 16px 
}

.itemAdmin-details-settings-price {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    margin-top: 0px;
    margin-bottom: 0px;

    color: #28A745;
}

.people {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    margin-top: 0px;
    margin-bottom: 0px;

    color: #6C757D
}