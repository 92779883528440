.pageBase{
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 24px;
}

.headerSection{
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

}

.headerWithBack{
    display: flex;
    flex-direction: row;
}
.tableBase{
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    height: 400px;

    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.tableHeader{
    position: fixed;
    height: 32px;
    background-color: aliceblue;
}
.tableBody{
    position: relative;
    top: 32px
}

.tableRow{
    height: 32px;
}