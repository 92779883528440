.printCard {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    align-items: center;
    width: 280px;
    height: 480px
}

.downloadBtn{
    margin: 32
}