.searchBase {
    display: flex;
    background-color: #F7F7F8;
    border-width: 1px;
    border-radius: 8px;
    border-color: #ccc;
    border-style: solid;
}

.searchField {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 8px
};