.pageBase{
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 24px;
    overflow-y: scroll;
}
.headerSection{
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
}

.headerSection-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
    padding-bottom: 24px;
}

.pageTitle{
    line-height: 48px;
    font-size: 24px;
    margin: 0px;
    color: #322724
}
.form-structure{
    display: flex;
    flex-direction: column;
}

.form-structure > * {
    margin-bottom: 16px;
}