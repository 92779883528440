.custom-select {
    display: flex;
    flex-direction: column;
    min-width: 350px;
    position: relative;
}

.testing {
    height: 48px;
    padding: 0 16px 0 10px;
    outline: 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
    font-size: 16px;
}