.wrapper{
    background-color: rgb(227, 242, 253);
    display: flex;
    flex-direction: row;
    height: inherit;
}

.structure{
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.mainHeader{
    background-color: green;
    height: 72px;
    width: 100%;
}