.pageBase {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 24px;
}

.headerSection {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

}

.charts-section {
    display: grid;
    grid-template-columns: 3fr;
    gap: 20px 16px;
    flex-grow: 1;
}

.chart {
    display: flex;
    background-color: white;
    flex-grow: 1;
    border-radius: 8px;
}