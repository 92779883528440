.menuBase {
    background-color: #F7F7F8;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    z-index: 2;
}

.restInfo {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 8px;
    gap: 16px;
    padding: 24px;
    margin: 16px;
    margin-top: -72px;
}

.restTopInfo {
    display: flex;
    flex-direction: row;
    gap: 16px
}

.restTopRightInfo {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex-grow: 1;
}

.restBottomInfo {
    display: flex;
    flex-direction: row;
    gap: 8px
}

.restName {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 0px;


    color: #54886B;
}

.restAddress {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    
    color: #6C757D;
}

.restWorking {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.cover {
    width: 100%;
    height: 176px;
    background-color: #54886B;
    z-index: 1;
}

.categoryRow {
    display: flex;
    flex-direction: row;
    gap: 8px;
    overflow-x: auto;
    position: sticky;
    top: 0;
    background-color: #F7F7F8;
    padding: 16px 0px;
    z-index: 1;
}

.categoryRow::-webkit-scrollbar {
    display: none;
  }