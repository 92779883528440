#float-label {
  display: flex;
  flex-direction: column;
  position: relative;
}

#float-label input {
  height: 32px;
  padding: 14px 16px 0 10px;
  outline: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  font-size: 16px;
}

#float-label label {
  font-size: 16px;
  padding: 0 12px;
  color: #999;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 16px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
}

#float-label:focus-within label {
  transform: translate(0, 6px) scale(0.75);
}

#float-label .Active {
  transform: translate(0, 6px) scale(0.75);
}