

.buttonLabel{
    font-size: 16px;
    line-height: 24px;
    margin: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* button types */

.fillBtn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #2c25b0;
    padding: 8px;
    color: #ffffff;
    border-radius: 24px;
    border-color: #2c25b0;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
}
.fillBtn:hover{
    background-color: #5049c7;
    border-color: #5049c7;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.outlineBtn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: transparent;
    padding: 8px;
    color: #2c25b0;
    border-radius: 24px;
    border-color: #2c25b0;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
}
.outlineBtn:hover{
    background-color: #2c25b0;
    color: #fff;
}

.textBtn{   
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: transparent;
    color: #333;
    padding: 8px;
    cursor: pointer;
    border: none;
    cursor: pointer;
}
.textBtn:hover{
    color: #2c25b0;
}